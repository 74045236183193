<template>
  <div>
    <h3 class="text-secondary mb-5">
      {{
        `${$t("page.event.eventTitle")}: ${post.title} - ${
          post.eventId.date
        } - ${post.eventId.time}`
      }}
    </h3>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <b-form>
        <validation-provider
          name="title"
          :rules="{ required: true }"
          v-slot="validationContext"
        >
          <b-form-group :label="$t('page.event.title') + '*'">
            <b-form-input
              :disabled="$_authUserIsDomi"
              :state="getValidationState(validationContext)"
              v-model="title"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          name="sede"
          :rules="{ required: true }"
          v-slot="validationContext"
        >
          <b-form-group :label="$t('page.event.select-condominium') + '*'">
            <b-form-select
              disabled
              :state="getValidationState(validationContext)"
              v-model="condominium"
              :options="condominiums"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          name="group"
          :rules="{ required: true }"
          v-slot="validationContext"
        >
          <b-form-group :label="$t('page.event.select-group') + '*'">
            <b-form-select
              disabled
              :state="getValidationState(validationContext)"
              v-model="group"
              :options="groups"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <b-row no-gutters style="width: 100%">
          <b-row no-gutters>
            <validation-provider
              name="date"
              :rules="{ required: true, date: 'DD/MM/YYYY' }"
              v-slot="validationContext"
            >
              <b-form-group :label="$t('date') + '*'">
                <b-input-group class="mb-3">
                  <b-form-input
                    name="date"
                    :state="getValidationState(validationContext)"
                    v-model="formattedDate"
                    type="text"
                    disabled
                    placeholder="DD/MM/YYYY"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append v-if="!$_authUserIsDomi">
                    <b-form-datepicker
                      v-model="date"
                      button-only
                      right
                      locale="it"
                      :min="minDate"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: '2-digit'
                      }"
                      aria-controls="example-input"
                      @context="onContext"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-row>
          <validation-provider
            name="time"
            :rules="{ required: true, time: 'HH:mm' }"
            v-slot="validationContext"
          >
            <b-form-group :label="$t('time') + '*'" class="ml-2">
              <b-form-input
                :state="getValidationState(validationContext)"
                v-model="time"
                type="time"
                locale="it"
                :disabled="$_authUserIsDomi"
              ></b-form-input>
            </b-form-group>
          </validation-provider>
          <validation-provider
            name="location"
            rules="required"
            v-slot="validationContext"
            style="flex-grow: 1"
          >
            <b-form-group :label="$t('location') + '*'" class="ml-2">
              <b-form-input
                :state="getValidationState(validationContext)"
                v-model="location"
                :disabled="$_authUserIsDomi"
                required
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-row>
        <validation-provider
          name="body"
          :rules="{ required: true }"
          v-slot="validationContext"
        >
          <b-form-group
            style="flex-grow: 1"
            :label="$t('page.event.text') + '*'"
            class="ml-2"
          >
            <b-form-textarea
              :disabled="$_authUserIsDomi"
              :state="getValidationState(validationContext)"
              v-model="body"
            ></b-form-textarea>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <b-row no-gutters class="mt-4">
          <label>{{ $t("page.event.images") }}</label>
        </b-row>
        <b-row no-gutters>
          <b-col v-if="!$_authUserIsDomi">
            <file-uploader
              v-if="!$_authUserIsDomi"
              fileTypes="image/*"
              @addFiles="addPhotos"
              :multiple="true"
            ></file-uploader>
          </b-col>
          <b-col class="pl-4">
            <b-table
              v-if="!$_authUserIsDomi"
              small
              :items="photos.current"
              :fields="photoFields"
            >
              <template #head()="data">
                <div>
                  {{ $t(data.label) }}
                </div>
              </template>
              <template #cell(photo)="data">
                {{ data.item.filename }}
              </template>
              <template #cell(remove)="data">
                <b-button
                  variant="danger"
                  size="sm"
                  @click="removePhoto(data.item, data.index)"
                >
                  <span>{{ $t("button.delete") }} </span>
                  <b-icon icon="backspace" />
                </b-button>
              </template>
            </b-table>
            <gallery :photos="photos.current" />
          </b-col>
        </b-row>
        <b-row no-gutters class="mt-4">
          <label>{{ $t("page.event.attachments") }}</label>
        </b-row>
        <b-row no-gutters>
          <b-col v-if="!$_authUserIsDomi">
            <file-uploader
              @addFiles="addFiles"
              :multiple="true"
            ></file-uploader>
          </b-col>
          <b-col :class="{ 'pl-4': !$_authUserIsDomi }">
            <b-table small :items="files.current" :fields="fileFields">
              <template v-if="!$_authUserIsDomi" #head()="data">
                <div>
                  {{ $t(data.label) }}
                </div>
              </template>
              <template #cell(document)="data">
                <span>{{ data.item.filename }}</span>
              </template>
              <template v-if="!$_authUserIsDomi" #cell(remove)="data">
                <b-button
                  variant="danger"
                  size="sm"
                  @click="removeFile(data.item, data.index)"
                >
                  <span>{{ $t("button.delete") }} </span>
                  <b-icon icon="backspace" />
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-form>
      <b-row no-gutters style="justify-content: end">
        <b-button variant="secondary" @click="$router.go(-1)">{{
          $t("button.cancel")
        }}</b-button>
        <b-button
          v-if="!$_authUserIsDomi"
          class="ml-2 text-white"
          :disabled="invalid"
          @click="updateEvent"
          >{{ $t("button.save") }}</b-button
        >
        <b-button
          v-if="!$_authUserIsDomi"
          class="ml-2 text-white"
          @click="remindEvent"
          >{{ $t("page.event.remind") }}</b-button
        >
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import FileUploader from "../components/Base/BaseFileDropUploader.vue";
import { BASE64_TO_FILE, FILE_TO_BASE64, RESIZE_IMG } from "../Utils";
import uniqueId from "lodash/uniqueId";
import dayjs from "dayjs";
import Gallery from "../components/Base/BaseGallery.vue";
import { authMixin } from "../mixins";

const localFileIdPrefix = "local_";

export default {
  components: {
    FileUploader,
    Gallery
  },
  mixins: [authMixin],
  async created() {
    this.$store.dispatch("condominium/retrieveCondominiums");
    this.$store.dispatch("groups/retrieveGroups");
    const post = await this.$store.dispatch("post/retrievePost", {
      condominiumId: this.$route.params.condominiumId,
      postId: this.$route.params.postId
    });

    this.files.current = post.documents;
    this.photos.current = post.photos.map(p => ({
      ...p,
      src: p.url
    }));
    this.body = post.body;
    this.title = post.title;
    this.formattedDate = post.eventId.date;
    this.time = post.eventId.time;
    this.location = post.eventId.location;
    this.consumerGroup = post.groups[0];
    this.condominium = post.condominiumId._id;
  },
  data() {
    const minDate = dayjs().toDate();
    return {
      files: {
        current: [],
        removed: []
      },
      photos: {
        current: [],
        removed: []
      },
      body: "",
      formattedDate: "",
      date: "",
      time: "",
      location: "",
      title: "",
      consumerGroup: undefined,
      condominium: undefined,
      minDate: minDate,
      photoFields: [
        { key: "photo", label: "photos" },
        { key: "remove", label: "" }
      ],
      fileFields: [
        { key: "document", label: "documents" },
        { key: "remove", label: "" }
      ]
    };
  },
  computed: {
    post() {
      return this.$store.getters["post/getCurrentPost"] || {};
    },
    groups() {
      return this.$store.getters["groups/getGroups"].map(g => ({
        value: g._id,
        text: g.name
      }));
    },
    group: {
      get() {
        return this.consumerGroup ? this.consumerGroup : null;
      },
      set(val) {
        this.consumerGroup = val;
      }
    },
    condominiums() {
      return this.$store.getters["condominium/getCondominiums"].map(c => ({
        value: c._id,
        text: c.name
      }));
    },
    hasPhotos() {
      return this.post.photos.current || this.post.photo;
    }
  },
  methods: {
    onContext(val) {
      this.formattedDate = val.activeFormatted;
    },
    getUpdatedFileLists(files) {
      return {
        addedFiles: files.current
          .filter(f => f._id.startsWith(localFileIdPrefix))
          .map(f => f.src || f.file),
        removedFiles: files.removed
      };
    },
    async updateEvent() {
      this.$store.commit("loader/SET_LOADER", true);
      try {
        const payload = {
          "groups[]": [this.group],
          body: this.body,
          type: "event",
          title: this.title,
          time: this.time,
          date: this.formattedDate,
          location: this.location
        };
        const postId = this.post._id;
        const { addedFiles, removedFiles } = this.getUpdatedFileLists(
          this.files
        );
        const {
          addedFiles: addedPhotos,
          removedFiles: removedPhotos
        } = this.getUpdatedFileLists(this.photos);

        if (addedFiles.length > 0) {
          await this.$store.dispatch("event/addDocuments", {
            postId,
            payload: { "documents[]": addedFiles }
          });
        }

        for (let i = 0; i < removedFiles.length; ++i) {
          await this.$store.dispatch("event/deleteDocument", {
            postId,
            documentId: removedFiles[i]._id
          });
        }

        if (addedPhotos.length > 0) {
          const newPhotos = addedPhotos.map(photo => {
            console.log(photo);
            return BASE64_TO_FILE(photo, "issue");
          });
          await this.$store.dispatch("event/addPhotos", {
            postId,
            payload: { "photos[]": newPhotos }
          });
        }

        for (let i = 0; i < removedPhotos.length; ++i) {
          await this.$store.dispatch("event/deletePhoto", {
            postId,
            photoId: removedPhotos[i]._id
          });
        }

        await this.$store.dispatch("event/updateEvent", {
          postId,
          condominiumId: this.condominium,
          payload
        });
        this.$router.push({
          name: "Events"
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async remindEvent() {
      try {
        this.$store.commit("loader/SET_LOADER", true);
        await this.$store.dispatch("event/remindEvent", {
          postId: this.post._id
        });
        setTimeout(() => {
          this.$router.go(-1);
        }, 400);
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    constructLocalFileData(file) {
      return {
        _id: uniqueId(localFileIdPrefix),
        filename: file.name || file.photo.name || "",
        src: file.src,
        file
      };
    },
    getValidationState({ valid, touched }) {
      if (touched) return valid ? null : valid;
      else return null;
    },
    removePhoto(photo, index) {
      if (index > -1) {
        this.photos.current.splice(index, 1);
      }
      if (!photo.filename.includes(localFileIdPrefix)) {
        this.photos.removed.push(photo);
      }
    },
    addFiles(files) {
      files.forEach(f =>
        this.files.current.push(this.constructLocalFileData(f))
      );
    },
    addPhotos(photos) {
      photos.forEach(async photo => {
        try {
          const base64 = await FILE_TO_BASE64(photo);
          const res = await RESIZE_IMG(base64, photo.type);
          this.photos.current.push(
            this.constructLocalFileData({
              photo,
              src: res
            })
          );
        } catch (error) {
          console.error(error);
        }
      });
    },
    removeFile(file) {
      const matchFileIndex = this.files.current.findIndex(
        f => f._id === file._id
      );
      if (matchFileIndex === -1) return;

      this.files.current.splice(matchFileIndex, 1);
      if (!file.filename.includes(localFileIdPrefix)) {
        this.files.removed.push(file);
      }
    }
  }
};
</script>

<style></style>
